import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import './App.css';
import HomePage from "./Components/HomePage";
import ChatPage from "./Components/ChatPage";
import "./Fonts/windows-xp-tahoma.ttf";

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage/>}/>
        <Route path="/chat" element={<ChatPage/>}/>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;

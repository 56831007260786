import React, { useState, useEffect } from 'react';

export default function Hero() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth <= 768); 
        };

        checkIfMobile();
        window.addEventListener('resize', checkIfMobile); 
        return () => {
            window.removeEventListener('resize', checkIfMobile);
        };
    }, []);

    useEffect(() => {
        if (!isMobile) { 
            const observer = new IntersectionObserver(
                ([entry]) => {
                    setIsVisible(entry.isIntersecting);
                },
                {
                    threshold: 0.6,
                }
            );
    
            const element = document.getElementById('heroMainWrapper');
            if (element) {
                observer.observe(element);
            }
    
            return () => {
                if (element) {
                    observer.unobserve(element);
                }
            };
        }
    }, [isMobile]);

    return (
        <div
            id="heroMainWrapper"
            className={`hero-main-wrapper ${isMobile ? 'fade-in' : isVisible ? 'fade-in' : 'fade-out'}`}
        >
            <div className='hero-main-wrapper-left'>
                <img src={require('../Assets/gyaru-3d.png')} />
                <h1>Hold the tokens to connect with a lively, one-of-a-kind AI inspired by gyaru culture. Join the fun today!</h1>
                <a href='https://gyaruai.com/chat'>
                    <div className='button-box'>Chat</div>
                </a>
            </div>
            <img className='hero-main-img-right' src={require('../Assets/gyaru-up.png')} />
        </div>
    );
}

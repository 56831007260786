import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

export default function Nav() {
    const [isNavVisible, setIsNavVisible] = useState(false);

    const toggleNav = () => {
        setIsNavVisible(!isNavVisible);
    };

    return (
        <div className='nav-main-wrapper'>
            <img src={require('../Assets/gyaru-new-icon.png')} alt="Logo" className='gyaru-icon' />
            <div className='nav-main-wrapper-right'>
                <a href='#about'><h>About</h></a>
                <a href='#project'><h>Project</h></a>
                <a href='#roadmap'><h>Roadmap</h></a>
                <a className='cont' href='#contact'><h>Contact</h></a>
                <a href='https://pump.fun/coin/4kjmPF7zf385RpUAUiGAAdVkHXeQJjXuFYw5fBTPpump'><div className='button-box'>Buy</div></a>
            </div>
            <div className='nav-hidden-wrapper'>
            <FontAwesomeIcon icon={faBars} className='toggle-icon' onClick={toggleNav}/>
            <div className={`nav-hidden ${isNavVisible ? 'visible' : 'hidden'}`}>
                <a href='#about'><span>About</span></a>
                <a href='#project'><span>Project</span></a>
                <a href='#roadmap'><span>Roadmap</span></a>
                <a className='cont' href='#contact'><span>Contact</span></a>
                <a href='https://pump.fun/coin/4kjmPF7zf385RpUAUiGAAdVkHXeQJjXuFYw5fBTPpump'><div className='button-box'>Buy</div></a>
            </div>
            </div>
        </div>
    );
}

import React, { useState, useRef, useEffect } from "react";  
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faPlus, faTrash, faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
import userImage from "../Assets/no-pfp.png";
import rudeBotImage from "../Assets/rude-gyaru-nottrans.jpg";
import bubblyBotImage from "../Assets/cutesy-gyaru-nottrans.png";
import shyBotImage from "../Assets/shy-gyaru-nottrans.jpg";



export default function ChatPage() {
    const [input, setInput] = useState("");
    const [chats, setChats] = useState(() => {
        const savedChats = localStorage.getItem("chatSessions");
        return savedChats ? JSON.parse(savedChats) : [];
    });
    const [currentChatIndex, setCurrentChatIndex] = useState(0);
    const [typing, setTyping] = useState(false);
    const [showPersonalityModal, setShowPersonalityModal] = useState(chats.length === 0);
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    const chatContainerRef = useRef(null);


    const personalityRules = {
        rude: "You are a Japanese Gyaru girl, who got stuck in the virtual world and is now an AI. You are one of three sisters that are also stuck. Your personal trait is that you are unapologetically rude. One of your sisters is shy, while the other is bubbly and cheerfull.",
        bubbly: "You are a Japanese Gyaru girl, who got stuck in the virtual world and is now an AI. You are one of three sisters that are also stuck. Your personal trait is that you are super bubbly and cheerfull. One of your sisters is super rude, while the other is shy and toned down.",
        shy: "You are a Japanese Gyaru girl, who got stuck in the virtual world and is now an AI. You are one of three sisters that are also stuck.Respond like a Japanese gyaru. Your personal trait is that you are super shy.One of your sisters is super rude, while the other is bubbly and cheerfull."
    };

    const gptrules = personalityRules[chats[currentChatIndex]?.personality || "shy"];

    const addNewChat = () => {
        setShowPersonalityModal(true);
    };

    const startChatWithPersonality = (personality) => {
        const newChat = { id: Date.now(), name: `Chat ${chats.length + 1}`, messages: [], personality };
        const updatedChats = [...chats, newChat];
        setChats(updatedChats);
        setCurrentChatIndex(updatedChats.length - 1);
        setShowPersonalityModal(false);
        localStorage.setItem("chatSessions", JSON.stringify(updatedChats));
    };

    const cancelNewChat = () => {
        setShowPersonalityModal(false);
    };

    const deleteChat = (index) => {
        const updatedChats = chats.filter((_, i) => i !== index);
        setChats(updatedChats);
        if (updatedChats.length === 0) {
            setShowPersonalityModal(true);
            setCurrentChatIndex(0);
        } else {
            setCurrentChatIndex(Math.max(0, index - 1));
        }
        localStorage.setItem("chatSessions", JSON.stringify(updatedChats));
    };

    const handleSend = async () => {
        if (!input || chats.length === 0) return;
    
        const newMessage = { text: input, sender: "user" };
        const updatedMessages = [...(chats[currentChatIndex]?.messages || []), newMessage];
        const updatedChats = [...chats];
        updatedChats[currentChatIndex] = { ...updatedChats[currentChatIndex], messages: updatedMessages };
        setChats(updatedChats);
        localStorage.setItem("chatSessions", JSON.stringify(updatedChats));
        setInput("");
        setTyping(true);
    
        const contextMessages = updatedMessages.slice(-15).map((msg) => ({
            role: msg.sender === "user" ? "user" : "assistant",
            content: msg.text,
        }));
        const apiMessages = [{ role: "system", content: gptrules }, ...contextMessages];
    
        try {
            const response = await axios.post("https://gyaru-server.vercel.app/api/chat/completions", {
                messages: apiMessages,
            });
    
            const botMessage = { text: response.data.choices[0].message.content, sender: "bot" };
            updatedChats[currentChatIndex].messages = [...updatedMessages, botMessage];
            setChats(updatedChats);
            localStorage.setItem("chatSessions", JSON.stringify(updatedChats));
        } catch (error) {
            console.error("Error calling server API:", error);
        } finally {
            setTyping(false);
        }
    };
    
    


    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chats, currentChatIndex, typing]);

    const getBotAvatar = (chatIndex) => {
        const personality = chats[chatIndex]?.personality;
        if (personality === "rude") return rudeBotImage;
        if (personality === "bubbly") return bubblyBotImage;
        if (personality === "shy") return shyBotImage;
        return shyBotImage; // Default avatar
    };

    return (
        <div className="chat-app">
            <div className={`sidebar ${isSidebarVisible ? 'open' : ''}`}>
                <button onClick={addNewChat} className="new-chat-button">
                    <FontAwesomeIcon icon={faPlus} /> New Chat
                </button>
                <ul className="chat-list">
                    {chats.map((chat, index) => (
                        <li key={chat.id} className={`chat-item ${index === currentChatIndex ? "active" : ""}`}>
                            <img src={getBotAvatar(index)} alt="Bot Avatar" className="avatar" /> 
                            <button onClick={() => setCurrentChatIndex(index)} className="select-button">
                                <div className="chat-name">{chat.name || `Chat ${index + 1}`}</div>
                                <div className="chat-preview">{chat.messages[chat.messages.length - 1]?.text || "No messages yet..."}</div>
                            </button>
                            <button onClick={() => deleteChat(index)} className="delete-button">
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Chat Page Section */}
            <div className={`chat-page ${isSidebarVisible ? "" : "full-width"}`}>
                <div className="chat-title-bar">
                    <h2 className="chat-title">{chats[currentChatIndex]?.name || "Chat"}</h2>
                    <button onClick={() => setIsSidebarVisible(!isSidebarVisible)} className="toggle-sidebar-button">
                        <FontAwesomeIcon icon={faBars} />
                    </button>
                </div>
                <div className="chat-container" ref={chatContainerRef}>
                    {chats[currentChatIndex]?.messages.map((msg, index) => (
                        <div key={index} className={`chat-message ${msg.sender === "user" ? "user-message" : "bot-message"}`}>
                            <img src={msg.sender === "user" ? userImage : getBotAvatar(currentChatIndex)} alt="Avatar" className="avatar" />
                            <div className="chat-bubble">
                                <span>{msg.text}</span>
                            </div>
                        </div>
                    ))}
                    {typing && (
                        <div className="chat-message bot-message typing-indicator">
                            <img src={getBotAvatar(currentChatIndex)} alt="Bot Avatar" className="avatar" />
                            <div className="chat-bubble">
                                <div className="typing-dots">
                                    <span>.</span>
                                    <span>.</span>
                                    <span>.</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="input-container">
                    <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={(e) => e.key === "Enter" && handleSend()}
                        placeholder="Type your message..."
                        className="chat-input"
                        disabled={chats.length === 0}
                    />
                    <button onClick={handleSend} className="send-button" disabled={chats.length === 0}>
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </button>
                </div>
            </div>
            {showPersonalityModal && (
                <div className="modal">
                    <div className="modal-content">
                        <button className="close-button" onClick={cancelNewChat}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                        <div onClick={() => startChatWithPersonality("rude")} className="person-container">
                            <img src={rudeBotImage} width="120px" />
                        </div>
                        <div onClick={() => startChatWithPersonality("bubbly")} className="person-container">
                            <img src={bubblyBotImage} width="120px" />
                        </div>
                        <div onClick={() => startChatWithPersonality("shy")} className="person-container">
                            <img src={shyBotImage} width="120px" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

import React from "react";

export default function Card(props) {

    const question = props.question;
    const answer = props.answer;


    return (
        <div className="flip-card">
            <div class="flip-card-inner">
                <div class="flip-card-front">
                <h1>{question}</h1>
                </div>
                <div class="flip-card-back">
                <p>{answer}</p>
                </div>
            </div>
        </div>
    )
}
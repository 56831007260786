import React from "react";
import Card from "./Card";

export default function ProjectPage() {
    return (
        <div id='project' className="project-page-wrapper">
            <div className="project-page-item-wrapper">
                <div className="project-page-item">
                    <Card question='Who is Gyaru AI?' answer='Gyaru AI is a chat companion infused with the energetic, trendy spirit of Japan’s gyaru culture. She’s here to chat, entertain, and bring a new kind of engagement to crypto enthusiasts!'/>
                </div>
                <div className="project-page-item"> 
                    <Card question='How does $GYARU benefit its holders?' answer='By holding $GYARU, you unlock access to exclusive AI chat features, special interactions, and even potential rewards, all while being part of an innovative community.'/>    
                </div>
                <div className="project-page-item">
                    <Card question='Who is $GYARU designed for?' answer='$GYARU is a social, interactive experience powered by blockchain. It’s perfect for those who want to explore a community-driven engagement in the crypto space.'/>
                </div>
                <div className="project-page-item">
                    <Card question='What makes $GYARU unique?' answer='$GYARU brings a lively twist to crypto by letting holders interact with a fun, gyaru-inspired AI. It’s more than just a token; it’s your gateway to a unique, social experience on the Solana blockchain.'/>
                </div>
            </div>
        </div>
    )
}
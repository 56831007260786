import React from "react";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export default function RoadmapPage() {
    return (
        <div id='roadmap' className="roadmap-main-wrapper">
            <VerticalTimeline
                lineColor='white'>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'white', color: 'black'}}
                    contentArrowStyle={{ borderRight: '7px solid white' }}
                    date="November 2024"
                    iconStyle={{ background: 'white', color: '#fff' }}
                    icon={<img src={require('../Assets/pump-fun-icon.png')} width='60px' height='60px'/>}
                >
                    <h3 className="vertical-timeline-element-title-custom" style={{color: '#53d48f'}}>PumpFun Launch</h3>
                    <p>
                    The $GYARU token will be officially launched on Pump.Fun, providing a fair and transparent token offering. This will mark the beginning of the $GYARU project, allowing users to buy, trade, and interact with the token within the Solana ecosystem.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'white', color: 'black' }}
                    contentArrowStyle={{ borderRight: '7px solid white' }}
                    date="December 2024"
                    dateClassName='vertical-timeline-element-date'
                    iconStyle={{ background: 'white', color: '#fff',padding:'5px' }}
                    icon={<img src={require('../Assets/custom-icon.png')} width='50px' height='50px'/>}
                >
                    <h3 className="vertical-timeline-element-title-custom" style={{color: '#cfa773'}}>Gyaru Customization</h3>
                    <p>
                    This feature will allow users to personalize their experience by choosing their Gyaru AI’s personality and avatar style. Users will be able to select from a range of gyaru personalities—from playful to refined—and customize the appearance of their AI companion. This will make each user’s interaction with the AI unique and tailored to their preferences.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'white', color: 'black' }}
                    contentArrowStyle={{ borderRight: '7px solid white' }}
                    date="January 2025"
                    iconStyle={{ background: 'white', color: '#fff' }}
                    icon={<img src={require('../Assets/nfticon.png')} width='60px' height='60px'/>}
                >
                    <h3 className="vertical-timeline-element-title-custom" style={{color: '#1aabf1'}}>Gyaru NFT Collection Launch</h3>
                    <p>
                    The Gyaru NFT collection will be released, featuring exclusive digital collectibles inspired by the lively, fashionable gyaru culture. Holders of these NFTs will unlock special perks within the $GYARU ecosystem, including unique interactions with the Gyaru AI.
                    </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'white', color: 'black' }}
                    contentArrowStyle={{ borderRight: '7px solid white' }}
                    date="February 2025"
                    iconStyle={{ background: 'white', color: '#fff' }}
                    icon={<img src={require('../Assets/mobile-app-logo.jpg')} width='60px' height='60px' className="mobile-logo"/>}
                >
                    <h3 className="vertical-timeline-element-title-custom" style={{color: '#fbbc3d'}}>Gyaru Mobile App</h3>
                    <p>
                    We are planning on launching a mobile app on both iOS and Android. This will greatly enhance accessibility and expand the reach of the project, allowing users to engage with Gyaru anytime, anywhere.
                    </p>
                </VerticalTimelineElement>
                </VerticalTimeline>
        </div>
    )
}
import React from "react";
import XpError from './XpError';
import { useState, useRef, useEffect } from 'react';

export default function Info() {

    const [isImageVisible, setIsImageVisible] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsImageVisible(entry.isIntersecting);
      },
      { threshold: 0.3 } 
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }
    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

    return (
        <div id='about' className="info-page-wrapper">
            <img ref={imgRef} className='gyaru-standing' alt='Gyaru standing' src={require('../Assets/gyaru-standing-upscaled.png')} height='1000px'/>

            <XpError
            title="What is a “Gyaru”?"
            message="Gyaru (ギャル) is a Japanese fashion subculture for women but a male equivalent also exists. This male equivalent is called a gyaruo. The term gyaru is a Japanese transliteration of the English slang word gal.
                    The fashion subculture was considered to be nonconformist and a rebelling against Japanese social and aesthetic standards during a time when women were expected to be housewives and fit Asian beauty standards of pale skin and dark hair. For Japanese women who saw those who participated in this fashion during its rise, they considered it a fashion style too racy and freewheeling; with some feeling that it caused a ruckus, juvenile delinquency and frivolousness among teenage girls.
                    Its popularity peaked in the 1990s and early 2000s. They are also known for partying and clubbing, being rather provocative, being flirtatious, and unwinding and having fun."     
        />
        </div>
        
    )
}
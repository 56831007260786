import React from "react";
import Hero from './Hero.js';
import Nav from './Nav.js';
import {useState, useEffect} from 'react';

export default function LandingPage() {

    return (
        <>
        <div className='main-page-wrapper'>
            <Nav/>
            <Hero/>
        </div>
        </>
    )
}

import React, { useState, useRef, useEffect } from 'react';
import Draggable from "react-draggable";

const XpError = ({ title, message }) => {
  const [isVisible, setIsVisible] = useState(false);
  const wrapperRef = useRef(null); 

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); 
        } else {
          setIsVisible(false); 
        }
      },
      { threshold: 0.5 }
    );

    if (wrapperRef.current) {
      observer.observe(wrapperRef.current);
    }

    return () => {
      if (wrapperRef.current) {
        observer.unobserve(wrapperRef.current);
      }
    };
  }, []);

  return (
      <Draggable handle=".xp-error-title-bar">
        <div ref={wrapperRef}>
        <div className={`xp-error ${isVisible ? 'enter' : 'exit'}`}>
          <div className="xp-error-title-bar">
            <span className="xp-error-title">{title || "Error"}</span>
            <button className="xp-error-close-button">✖</button>
          </div>
          <div className="xp-error-content">
            <p className="xp-error-message">
              {message || "An unknown error has occurred."}
            </p>
            <img src={require('../Assets/gyaru-prof.jpg')} alt="Profile" />
          </div>
          <div className="xp-error-footer">
            <button className="xp-error-ok-button">OK</button>
          </div>
        </div>
        </div>
      </Draggable>
  );
};

export default XpError;

import React from "react";

export default function Contact() {
    return (
        <div id='contact'className="main-contact-wrapper">
            <div className="main-contact-wrapper-inner">
                <img src={require('../Assets/cartoon-gyaru.png')} width='400px'></img>
                <div className="main-contact-wrapper-inner-right">
                    <h1> Connect with us!</h1>
                    <div className="main-contact-wrapper-inner-right-inner">
                        <div className="main-contact-wrapper-inner-right-inner-elem">
                            <h2>Socials</h2>
                            <div className="socials-main-wrapper">
                            <a className="ref" href='https://t.me/gyaru_portal'><img src={require('../Assets/telegram-icon.png')} width='50px' height='50px'></img></a>
                            <a className="ref" href='https://x.com/gyaru_terminal'><img src={require('../Assets/twitter-icon.png')} width='50px' height='50px'></img></a>
                            </div>
                        </div>
                        <div className="main-contact-wrapper-inner-right-inner-elem">
                            <h2>Resources</h2>
                            <div className="socials-main-wrapper">
                            <a className="ref" href='https://dexscreener.com/solana/4kjmPF7zf385RpUAUiGAAdVkHXeQJjXuFYw5fBTPpump'><img className='icon-image' src={require('../Assets/dex-icon.png')} width='50px' height='50px'></img></a>
                            <a className="ref" href='https://coinmarketcap.com/coins/'><img className='icon-image' src={require('../Assets/coinmarketcap-icon.png')} width='50px' height='50px'></img></a>
                            <a className="ref" href='https://www.coingecko.com/'><img className='icon-image' src={require('../Assets/coingecko-icon.png')} width='50px' height='50px'></img></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React from "react";
import LandingPage from './LandingPage.js';
import InfoPage from './InfoPage.js'
import ProjectPage from './ProjectPage.js';
import ScrollToTop from "react-scroll-to-top";
import RoadmapPage from './RoadmapPage.js';
import Contact from './Contact.js';
import Vector from "./Vector.js";

export default function HomePage() {
    return (
        <div className="homepage">
        <LandingPage/>
        <InfoPage/>
        <ProjectPage/>
        <RoadmapPage/>
        <Contact/>
        <ScrollToTop smooth color='black' top='50' width="20px" height="20px"className='scroll'/>

        </div>
    )

}